// 登录鉴权
// function authLogin(){
//
// }
import router from '../router/index'
import store from '../store/index'

let times = null;
//清除用户数据
export const clearUserData = function () {

    //清除指定缓存的数据
    localStorage.clear()
    localStorage.removeItem(process.env.VUE_APP_SEARSELLER_KEY)
    localStorage.removeItem('autoTime')
    //
    // //清除vuex里面的数据
    store.commit('setting/clearData', null)
	store.getters['setting/userToken'];
	if(times)return false;
	times = setTimeout(()=>{
		clearTimeout(times)
		times = null;
		router.push('/login')
	},500)
}

