import Vue from 'vue'
import Vuex from 'vuex'
import setting from "./setting/setting";
Vue.use(Vuex)

export default new Vuex.Store({
	namespaced: true,
	state: {
		uploadTypeList: {
			0: 'member:avatar',// 会员头像
			1: 'member:id:card',// 身份证
			2: 'member:ali:pay',// 支付宝认证
			3: 'member:screenshot',// 截图凭证
			4: 'admin:goods',// 商品图片
			5: 'admin:goods:water',// 商品水印图片
			7: 'admin:shop',// 店铺图片
			8: 'admin:platform',// 平台图片
			9: 'admin:avatar',// 后台头像
			10: 'appraise:picture',// 评价图片
			11: 'appraise:video',// 评价视频
			12: 'fee:screenshot',// 费用凭证
			13: 'task:type:steps',// 任务类型步骤说明
			14: 'other:screenshot',// 其他图片
			15: 'account:screenshot',// 平台账号凭证图片
			16: 'info:video',// 资讯视频
			17: 'info:picture',// 资讯图片
			18: 'info:video:cover',// 资讯视频封面
			19: 'task:related:picture',// 任务相关
			20: 'receiving:code:screenshot',// 收款码图
			21: 'level:screenshot',// 等级勋章
		},
	},
	mutations: {},
	actions: {},
	modules: {
		setting
	}
})
