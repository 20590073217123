import Vue from 'vue';
import axios from 'axios';
import { Loading }  from 'element-ui'
let stringify = require('qs-stringify')

import {responseSuccessHandle, responseErrorHandle, requireConfigHandle, auth} from './httpsTools'

const timeout = 300000

// 正在进行中的请求列表
let reqList = [];
/**
 * 阻止重复请求
 * @param {array} reqList - 请求缓存列表
 * @param {string} url - 当前请求地址
 * @param {function} cancel - 请求中断函数
 * @param {string} errorMessage - 请求中断时需要显示的错误信息
 */
const stopRepeatRequest = function (reqList, url, cancel, errorMessage) {
    const errorMsg = errorMessage || ''
    for (let i = 0; i < reqList.length; i++) {
        if (reqList[i] === url) {
            cancel(errorMsg)
            return
        }
    }
    reqList.push(url)
}

/**
 * 允许某个请求可以继续进行
 * @param {array} reqList 全部请求列表
 * @param {string} url 请求地址
 */
const allowRequest = function (reqList, url) {
    for (let i = 0; i < reqList.length; i++) {
        if (reqList[i] === url) {
            reqList.splice(i, 1)
            break
        }
    }
}


const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout,
    headers: {}
});
axiosInstance.defaults.withCredentials = true;
//添加请求拦截器
axiosInstance.interceptors.request.use(function (config) {
    //请求前鉴权
    auth()
    // 阻止重复请求。当上个请求未完成时，相同的请求不会进行
    let cancel;
    config.cancelToken = new axios.CancelToken(function (c) {
        cancel = c
    })
	console.log('取消请求')
    stopRepeatRequest(reqList, config.url, cancel, `请不要频繁请求`)

    //处理请求配置
    config = requireConfigHandle(config);
    return config;
}, function (error) {
    //请求错误时做些事
    return Promise.reject(error);
});

// 添加响应拦截器
axiosInstance.interceptors.response.use(function (response) {
    console.log(response);
    //reqList清除已响应的请求url
    allowRequest(reqList, response.config.url);
    //处理响应的状态码
    responseSuccessHandle(response.data);
    return response.data;
}, function (error) {
    //处理响应错误事件
    responseErrorHandle(error);
    return Promise.reject(error);
});

let ajax = {
    get: (url, params = {}) => {
        return axiosInstance.get(url, {params})
    },
    form: (url, params,configs) => {
        let config = {
            headers:{
                'Content-type': 'multipart/form-data'
            },
            timeout:300000,
            ...configs
        }
        return axiosInstance.post(url, params,config)
    },
    post: (url, params) => {
        params = stringify(params);
        // return new Promise((then,catc)=>{
        //     const loading = Loading.service()
        //     return axiosInstance.post(url, params).then(res=>{
        //         then(res);
        //         loading.close()
        //     }).catch(err=>{
        //         loading.close();
        //         catc(err);
        //         console.log(err)
        //     })
        // })
        return  axiosInstance.post(url, params)
    },
    put: (url, params) => {
        params = stringify(params)
        // return axiosInstance.put(url, params)
        return new Promise((then,catc)=>{
            const loading = Loading.service();
            return axiosInstance.put(url, params).then(res=>{
                then(res);
                loading.close()
            }).catch(err=>{
                loading.close();
                catc(err);
                console.log(err)
            })
        })
    },
    delete: (url, params) => {
        params = stringify(params)
        // return axiosInstance.delete(url, params)
        return new Promise((then,catc)=>{
            const loading = Loading.service();
            return axiosInstance.delete(url, params).then(res=>{
                then(res);
                loading.close()
            }).catch(err=>{
                loading.close();
                catc(err);
                console.log(err)
            })
        })
    },
}

Vue.prototype.$axios = ajax;