import router from './index.js';
import store from '../store/index'
// next(false) next('/')
router.beforeEach((to,from,next)=>{
	// 如果用户未能验证身份，跳转登录页面。
	// console.log(to);
	// console.log(from);
	var arr = ['/login','/register'];
	var iss = arr.some((item)=>to.path.indexOf(item) !=-1);
	store.getters['setting/userToken'];
	if(to.path == from.path)return next(false)
	if(!store.state.setting.userToken&&!iss){
		next('login')
	}else{
		next()
	}
})

// 你也可以注册全局后置钩子，然而和守卫不同的是，这些钩子不会接受 next 函数也不会改变导航本身：
router.afterEach((to, from) => {
	console.log(to)
	console.log(from)
  // ...
})