export default {
    namespaced: true,
    state: {
        userToken: '',
        autoTime:null,
        userInfo:null,
        oneTaskInfo:null,
    },
    mutations: {
        clearData(state){
            localStorage.removeItem(process.env.VUE_APP_SELLERINFO_KEY);
            localStorage.removeItem(process.env.VUE_APP_SEARSELLER_KEY);
            localStorage.removeItem('autoTime');

            state.userInfo = null;
            state.autoTime = '';
            state.userToken = '';

        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo;
			if(!userInfo)return localStorage.removeItem(process.env.VUE_APP_SELLERINFO_KEY)
            let userInfoStr = JSON.stringify(userInfo)
            localStorage.setItem(process.env.VUE_APP_SELLERINFO_KEY, userInfoStr)
        },
        setOneTaskInfo(state, oneTaskInfo) {
            state.oneTaskInfo = oneTaskInfo;
            let oneTaskInfoStr = JSON.stringify(oneTaskInfo)
            if(!oneTaskInfo)return localStorage.removeItem('sellerId_oneTask_'+state.userInfo?.sellerId)
            localStorage.setItem('sellerId_oneTask_'+state.userInfo?.sellerId, oneTaskInfoStr)
        },
        setUserToken(state, token) {
            state.userToken = token;
			if(!token)return localStorage.removeItem(process.env.VUE_APP_SEARSELLER_KEY)
            localStorage.setItem(process.env.VUE_APP_SEARSELLER_KEY, token)
        },
        setAutoTime(state, autoTime) {
            state.autoTime = autoTime;
			if(!autoTime)return localStorage.removeItem('autoTime')
            autoTime = JSON.stringify(autoTime)
            localStorage.setItem('autoTime', autoTime)
        }
    },
    getters: {
        oneTaskInfo(state){
            if (!state.oneTaskInfo) {
                try {
                    const oneTaskInfo = localStorage.getItem('sellerId_oneTask_'+state.userInfo?.sellerId);
                    let oneTaskInfoObj = JSON.parse(oneTaskInfo);
                    state.oneTaskInfo = oneTaskInfoObj
                } catch (e) {
                    console.error(e)
                }
            }
            console.log(state.oneTaskInfo)
            return state.oneTaskInfo || {}
        },
        userInfo(state){
            if (!state.userInfo) {
                try {
                    const userInfo = localStorage.getItem(process.env.VUE_APP_SELLERINFO_KEY);
                    let userInfoObj = JSON.parse(userInfo);
                    console.log(userInfoObj)
                    state.userInfo = userInfoObj
                } catch (e) {
                    console.error(e)
                }
            }
            return state.userInfo || {}
        },
        autoTime(state){
            if (!state.autoTime) {
                try {
                    const autoTime = localStorage.getItem('autoTime');
                    let autoTime2 = JSON.parse(autoTime)
                    console.log(autoTime2)
                    state.autoTime = autoTime2
                } catch (e) {
                    console.error(e)
                }

            }
            return state.autoTime || {}
        },
        userToken(state) {
            if (!state.userToken) {
                try {
                    const sellerToken = localStorage.getItem(process.env.VUE_APP_SEARSELLER_KEY);
                    state.userToken = sellerToken
                } catch (e) {
                    console.error(e)
                }

            }
            return state.userToken
        }
    },
    actions: {},
    modules: {}
}