// 消息提示
import {
	Message
} from 'element-ui'


export function mes({
	message,
	type = 'success'
}) {
	Message({
		message,
		type,
	});
}
