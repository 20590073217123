import {mes} from '../tools/packaging'
import {clearUserData} from "../tools/cache";
import store from "../store";

const xsrfHeaderName = 'x-auth-token';


/**
 * @param data 请求返回数据
 * */
//处理请求成功返回事件
export const responseSuccessHandle = function (data) {
    if (data.code !== 200) {
        // mes({message: data.msg, type: 'warning'});
        if (data.code == 403) {
            // console.log('token过期了')
            clearUserData()
            return
        }else{
            mes({message: data.msg, type: 'error'});
        }
    }
};

/**
 * @param error 错误信息体
 * */
export const responseErrorHandle = function (error) {

    if (error.message.indexOf('timeout') != -1) {
        mes({message: '请求超时', type: 'error'});
    }
};

/**
 * @param error 错误信息体
 * */
export const auth = function () {
    if (store.state.autoTime && !store.getters['setting/autoTime']) {
        // console.log('token过期了')
        clearUserData()
    }
};
/**
 * @param config 处理请求配置
 * */
export const requireConfigHandle = function (config) {
    //设置请求头
    config.headers[xsrfHeaderName] = store.getters['setting/userToken'];
    return config
};

