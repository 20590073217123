import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
	{
		path: '/',
		redirect: '/pageIndex/sellerCenter'
	},
	{
		path: '/login',
		component: () => import('../views/login/login.vue'),
	},

	{
		path: '/register',
		component: () => import('../views/register/register.vue'),
	},
	{
		path: '/pageIndex',
		component: () => import('../views/pageIndex.vue'),
		children: [

			{
				path: 'bill',
				component: () => import('../views/bill/bill.vue'),
				meta: {
					name: '资金明细'
				}
			},
			{
				path: 'rechargeList',
				component: () => import('../views/rechargeList/rechargeList.vue'),
				meta: {
					name: '充值记录'
				}
			},
			{
				path: 'taskBill',
				component: () => import('../views/taskBill/taskBill.vue'),
				meta: {
					name: '任务账单'
				}
			},
			{
				path: 'orderInfo',
				component: () => import('../views/orderInfo/orderInfo.vue'),
				meta: {
					name: '订单管理'
				}
			},
			{
				path: 'taskList',
				component: () => import('../views/taskList/taskList.vue'),
				meta: {
					name: '任务列表'
				}
			},
			{
				path: 'evaluate',
				component: () => import('../views/evaluate/evaluate.vue'),
				meta: {
					name: '评价管理'
				}
			},
			{
				path: 'coupon',
				component: () => import('../views/coupon/group.vue'),
				meta: {
					name: '优惠管理'
				}
			},
			{
				path: 'sellerCenter',
				component: () => import('../views/sellerCenter/sellerCenter.vue'),
				meta: {
					name: '商家中心'
				}
			},
			{
				path: 'shopManagement',
				component: () => import('../views/shopManagement/shopManagement.vue'),
				meta: {
					name: '店铺管理'
				}
			},
			{
				path: 'goodsManagement',
				component: () => import('../views/goodsManagement/goodsManagement.vue'),
				meta:{
					name:'宝贝管理'
				}
			},
			{
				path: 'sellerInfo',
				component: () => import('../views/sellerInfo/sellerInfo.vue'),
				meta:{
					name:'个人基础信息'
				}
			},
			{
				path: 'forgetPassword',
				component: () => import('../views/forgetPassword/forgetPassword.vue'),
				meta:{
					name:'设置密码'
				}
			},
			{
				path: 'recharge',
				component: () => import('../views/recharge/recharge.vue'),
				meta:{
					name:'账户充值'
				}
			},
			{
				path: 'taskEnter',
				component: () => import('../views/taskEnter/taskEnter.vue'),
				meta:{
					name:'选择任务发布'
				}
			},
			{
				path: 'taskone',
				component: () => import('../views/taskone/taskone.vue'),
				meta:{
					name:''
				}
			},
			{
				path: 'taskTwo',
				component: () => import('../views/taskTwo/taskTwo.vue'),
				meta:{
					name:''
				}
			},
			{
				path: 'sellerTools',
				component: () => import('../views/sellerTools/sellerTools.vue'),
				children:[
					{
						path: 'rank',
						component: () => import('../views/sellerTools/toolsItem/rank.vue'),
						meta:{
							name:'排名管理'
						}
					},
					{
						path: 'blackQuery',
						component: () => import('../views/sellerTools/toolsItem/blackQuery.vue'),
						meta:{
							name:'黑号查询'
						}
					},
					{
						path: 'mark',
						component: () => import('../views/sellerTools/toolsItem/mark.vue'),
						meta:{
							name:'旺旺自动打标'
						}
					},
					{
						path: 'firstPage',
						component: () => import('../views/sellerTools/toolsItem/firstPage.vue'),
						meta:{
							name:'关键词卡首屏'
						}
					},
					{
						path: 'guessLike',
						component: () => import('../views/sellerTools/toolsItem/guessLike.vue'),
						meta:{
							name:'猜你喜欢'
						}
					},
					{
						path: 'transform',
						component: () => import('../views/sellerTools/toolsItem/transform.vue'),
						meta:{
							name:'指数转换'
						}
					},
					{
						path: 'liar',
						component: () => import('../views/sellerTools/toolsItem/liar.vue'),
						meta:{
							name:'骗子库'
						}
					},
					{
						path: 'checkTaoKe',
						component: () => import('../views/sellerTools/toolsItem/checkTaoKe.vue'),
						meta:{
							name:'淘客检测'
						}
					},
				]
			},

		]
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
