import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './router/routerGuard'

import store from './store'

import './plugins/element.js'
import '../theme/index.css'
import './api/require.js'

// 自定义信息弹窗
import {
	mes
} from './tools/packaging.js'

// 引进clipboard2复制模板
import VueClipboard from 'vue-clipboard2'

/*初始化state*/
import './tools/initState.js'

Vue.use(VueClipboard)

Vue.prototype.$mes = mes;
Vue.config.productionTip = false

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
